.navbar {
  flex: 1;
  display: flex;
  overflow: hidden;

  .pagination {
    flex-shrink: 0;
    width: 50px;
    height: var(--layout-header-height);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .nav-list {
    display: flex;
    transition: all 200ms ease-in-out;

    .nav {
      flex-shrink: 0;
      height: var(--layout-header-height);
      width: 100px;
      line-height: var(--layout-header-height);
      text-align: center;
      cursor: pointer;
      position: relative;

      .close {
        display: none;
        position: absolute;
        right: 6px;
        top: 6px;
        font-size: 12px;
        transform: scale(0.8);
        &:hover {
          color: #f5222d;
        }
      }
    }

    .nav.current {
      background: var(--main-color);
    }

    .nav.hover-with-closeable:hover {
      .close {
        display: block;
      }
    }
  }
}
